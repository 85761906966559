import { prettyCamel } from "../../../reactor/Helpers"
import { server } from "../../../server"
import { logout } from "../../../packages/ssr/Login"
import { linkBlue } from "./colors"
import { ProblemJson } from "../../../packages/ssr/ProblemJson"

/** Shows a loading indicator or error message depending on the status of the resource */
export function Missing<T>(props: {
    data: T
    error?: ProblemJson
    loading: boolean
    waitingForInputs?: string[]
}) {
    if (props.loading) {
        return <Loading />
    }
    if (props.error) {
        return (
            <div style={{ margin: 32, padding: 32 }}>
                <img
                    src={`${server()}/static/sayfr-small.png`}
                    style={{
                        height: 38,
                        width: 38,
                    }}
                />
                <h3>
                    {props.error.status} {prettyCamel(props.error.type)}
                </h3>
                <div style={{ marginTop: 32 }}>{(props.error as any).detail}</div>

                {props.error.status === 403 && (
                    <>
                        <div
                            style={{
                                marginTop: 32,
                                display: "inline-block",
                            }}
                        >
                            Try{" "}
                            <span
                                onClick={() => logout("/modules")}
                                style={{
                                    color: linkBlue,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                            >
                                logging out and logging back in
                            </span>{" "}
                            with the exact email that received the invitation.
                        </div>
                        <div style={{ marginTop: 32 }}>
                            If the problem persists, please contact support.
                        </div>
                    </>
                )}
                {"stack" in props.error && (
                    <div style={{ marginTop: 32 }}>
                        {(props.error as any).stack.split("\n").map((s: string) => (
                            <div>{s}</div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
    if (props.waitingForInputs) {
        return <div>Waiting for {props.waitingForInputs.join(", ")}</div>
    }
    return <div>Missing: {JSON.stringify(props)}</div>
}

function Loading() {
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div style={{ flex: 1 }} />
            <div>Loading...</div>
            <div style={{ flex: 1 }} />
        </div>
    )
}
