import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { useSimulation } from "./client"

export function ScreenplayView() {
    const { id } = useParams()
    const { data: sim } = useSimulation((id as any) ?? null)
    const [highlightCharacter, setHighlightCharacter] = useState<string>()
    const localize = useLocalize()

    if (sim?.characters[0] && !highlightCharacter) {
        setHighlightCharacter(sim?.characters[0].id.valueOf())
    }

    if (!sim) return <></>

    const black = "#000"
    const gray = "#aaa"

    return (
        <div style={{ padding: 64, fontFamily: "Courier", fontSize: 18 }}>
            <div
                style={{
                    textAlign: "center",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    marginBottom: 32,
                }}
            >
                {localize(sim.name)}
            </div>

            <div style={{ marginTop: 16, marginBottom: 32 }}>
                Highlight lines for character:
                <select
                    style={{ marginLeft: 32, padding: 8 }}
                    value={highlightCharacter}
                    onChange={(e) => setHighlightCharacter(e.target.value)}
                >
                    {sim?.characters.map((c) => <option value={c.id.valueOf()}>{c.name}</option>)}
                </select>
            </div>
            {sim?.scenes.map((scene) => {
                const highlightScene = scene.steps.some(
                    (s) =>
                        (s.type === "Say" && s.character === highlightCharacter) ||
                        (s.type === "Dialog choice" && s.prompt?.character === highlightCharacter)
                )
                return (
                    <div style={{ color: highlightScene ? black : gray }}>
                        <p>
                            <b>
                                {scene.name.toUpperCase()} -{" "}
                                {scene.env ? scene.env.toUpperCase() : "STILL IMAGE"}
                            </b>
                        </p>
                        <div style={{ margin: 32 }}>
                            <div style={{ marginBottom: 32 }}></div>
                            {scene.steps.map((step) => {
                                if (step.type === "Narrative")
                                    return (
                                        <div style={{ marginBottom: 32 }}>
                                            {localize(step.text)}{" "}
                                        </div>
                                    )

                                if (step.type === "Say")
                                    return (
                                        <div
                                            style={{
                                                margin: 64,
                                                color:
                                                    highlightScene &&
                                                    step.character === highlightCharacter
                                                        ? black
                                                        : gray,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginLeft: "20%",
                                                    marginRight: "20%",
                                                }}
                                            >
                                                <div style={{ textAlign: "center" }}>
                                                    {sim.characters
                                                        .find((c) => c.id === step.character)
                                                        ?.name.toUpperCase()}{" "}
                                                    {step.emotion ? `[${step.emotion}]` : ""}
                                                </div>
                                                {localize(step.text)}
                                            </div>
                                        </div>
                                    )

                                if (step.type === "Dialog choice")
                                    return (
                                        <div style={{ margin: 64 }}>
                                            <div
                                                style={{
                                                    marginLeft: "20%",
                                                    marginRight: "20%",
                                                    color:
                                                        highlightScene &&
                                                        step.prompt?.character ===
                                                            highlightCharacter
                                                            ? black
                                                            : gray,
                                                }}
                                            >
                                                <div style={{ textAlign: "center" }}>
                                                    {sim.characters
                                                        .find(
                                                            (c) => c.id === step.prompt?.character
                                                        )
                                                        ?.name.toUpperCase()}
                                                </div>
                                                {step.prompt?.text && localize(step.prompt?.text)}
                                                {step.options.map((option, i) => (
                                                    <div style={{ margin: 32, color: gray }}>
                                                        <div style={{ textAlign: "center" }}>
                                                            PLAYER (ALTERNATIVE #{i + 1})
                                                        </div>
                                                        {localize(option.text)}
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{ marginTop: 64 }}>
                                                &gt;&gt; Next step in dialogue depends on the
                                                player's choices so far in the game.
                                            </div>
                                        </div>
                                    )
                                if (step.type === "Script")
                                    return (
                                        <div style={{ width: "100%", marginBottom: 64 }}>
                                            &gt;&gt; Next step in dialogue depends on the player's
                                            choices so far in the game (Script)
                                        </div>
                                    )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
