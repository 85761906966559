import React, { useState } from "react"
import { server } from "../../../server"
import { SimulationPlayer } from "./SimulationPlayer"
import { GetSimulationDto, postRun, Run, useSimulation, useSimulations } from "./client"
import { Uuid } from "../../../reactor/Types/Primitives"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"

export function FeedbackUtilityHome() {
    const [run, setRun] = useState<Run | null>(null)

    const { data: sims } = useSimulations()
    const [selectedSim, setSelectedSim] = useState<Uuid<"Simulation"> | null>(null)

    const { data: sim } = useSimulation(selectedSim)
    const [started, setStarted] = useState(false)
    const [seed, setSeed] = useState(Math.floor(Math.random() * 99))

    const localize = useLocalize()

    if (sim && started && run)
        return (
            <SimulationPlayer
                sim={sim}
                seed={seed}
                unity={!!sim.useUnity}
                run={run}
                showFeedbackToolbar={true}
                exit={() => setStarted(false)}
            />
        )
    else
        return (
            <div style={{ padding: 32, height: "100%", backgroundColor: "#444" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: 1 }}>
                        <img
                            style={{
                                width: 150,
                                marginLeft: 16,
                                marginBottom: 32,
                                objectFit: "contain",
                            }}
                            src={`${server()}/static/sayfr.png`}
                        />
                    </div>
                    {/*<div
                        style={{ padding: 24, color: "white", cursor: "pointer" }}
                        onClick={accessTokenReady ? auth?.signOut : auth?.signIn}>
                        {accessTokenReady ? "Log out" : "Admin Login"}
                        </div>*/}
                </div>
                <div style={{ backgroundColor: "white", borderRadius: 16, padding: 32 }}>
                    <div>
                        Welcome to the{" "}
                        <span style={{ fontWeight: "bold" }}>Sayfr Content Feedback Utility</span>
                    </div>
                    <div style={{ marginTop: 16, marginBottom: 16 }}>
                        Thank you for helping us improve the content by testing and providing your
                        feedback.
                    </div>
                    <div>
                        To make the content easier to change based on your feedback;
                        <ul>
                            <li>Synthetic voices are used instead of real actors</li>
                            <li>Only still images are used instead of animated 3D</li>
                        </ul>
                        The final production will include 3D animations and real voice recordings.
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        Select the simulation to test:
                        <select
                            value={selectedSim?.valueOf()}
                            onChange={(ev) =>
                                setSelectedSim(ev.target.value as any as GetSimulationDto["id"])
                            }
                            style={{
                                padding: 8,
                                borderRadius: 8,
                                marginBottom: 16,
                                marginTop: 8,
                                maxWidth: 350,
                            }}
                        >
                            <option value="">Please select...</option>
                            {sims &&
                                sims.map((s) => (
                                    <option key={s.id.toString()} value={s.id.toString()}>
                                        {localize(s.name)}
                                    </option>
                                ))}
                        </select>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        Select a random seed (a number between 0 and 99)
                        <div style={{ fontWeight: 200, fontSize: 16, maxWidth: 600 }}>
                            Using the same number gives exactly the same simulation each time.
                            Different numbers may give slight variations of the simulation. When
                            providing feedback, this number will be included automatically so the
                            exact scenario can be reproduced by our testers.
                        </div>
                        <input
                            value={seed}
                            style={{
                                padding: 8,
                                borderRadius: 8,
                                marginBottom: 16,
                                marginTop: 8,
                                maxWidth: 350,
                                borderWidth: 1,
                            }}
                            onChange={(ev) => {
                                const val = Math.min(99, Math.max(0, parseInt(ev.target.value)))
                                setSeed(isNaN(val) ? 0 : val)
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <button
                            disabled={!selectedSim}
                            onClick={async () => {
                                if (!sim) throw new Error("Simulation not loaded")
                                try {
                                    setRun(await postRun(sim.id))
                                } catch (error: any) {
                                    alert(error.detail ?? error.message)
                                    return
                                }
                                setStarted(true)
                            }}
                        >
                            Start simulation
                        </button>
                    </div>
                </div>
            </div>
        )
}
