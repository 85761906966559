import React from "react"
import { ImageOptions, ImageToUrl, VideoOptions, VideoToUrl } from "../../reactor/Types/File"

export function EditableVideo<T, TKey extends keyof T>({
    obj,
    prop,
    posterProp,
    style,
    className,
    children,
    options,
    posterOptions,
    innerRef,
}: {
    obj: T
    prop: TKey
    posterProp?: TKey
    style?: React.CSSProperties
    className?: string
    children?: React.ReactNode
    options?: VideoOptions
    posterOptions?: ImageOptions
    innerRef?: any
}) {
    const value = obj[prop] as any
    const poster = posterProp ? (obj[posterProp] as any) : undefined

    return (
        <div
            ref={innerRef}
            className={className}
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                ...style,
            }}
        >
            <video
                src={VideoToUrl(value, options)}
                poster={poster && ImageToUrl(poster, posterOptions)}
                width={style?.width}
                height={style?.height}
                controls
            >
                Your browser does not support the video tag.
            </video>
            {children}
        </div>
    )
}
