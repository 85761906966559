import React, { useCallback, useRef, useState } from "react"
import { server } from "../../../server"
import { RButton } from "../../../studio/Views/Buttons"
import { Simulate } from "./Simulate"
import { useStateful } from "./useStateful"
import { Unity } from "./Unity"
import { SendUnityMessage, SetUnityState } from "./UnityController"
import {
    Character,
    GetSimulationDto,
    postRunAction,
    postRunScore,
    StepDto,
    postFeedback,
    useSimulation,
    Run,
    DialogOption,
    putRunSavedGame,
} from "./client"
import { useHover } from "../../../packages/hooks/useHover"
import { FeedbackToolbar } from "./FeedbackToolbar"
import { correctGreen, darkMustard } from "./colors"
import { ArrowLeft, ExitButton } from "./ExitButton"
import { ScoreScreen } from "./ScoreScreen"
import { useEnvelope } from "./useEnvelope"
import interpolate from "color-interpolate"
import { Button } from "./Button"
import { Markdown, Uuid } from "../../../reactor/Types/Primitives"
import { useInterval } from "../../../packages/hooks/useInterval"
import { ReflectionView } from "./ReflectionView"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../packages/localization/client-side/useLocalize"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

let audio: HTMLAudioElement | undefined
let audioCallback: (() => void) | undefined

const preloadedHashes = new Set<string>()

export function SimulationPlayerLoader({
    simId,
    run,
    exit,
}: {
    simId: Uuid<"Simulation">
    run: Run
    exit: (shouldConfirm: boolean, personalityTypes?: string[]) => void
}) {
    const [seed] = useState(() => Math.floor(Math.random() * 99))
    const { data: simulation } = useSimulation(simId)
    if (!simulation) return <div>Loading...</div>
    return (
        <SimulationPlayer
            sim={simulation}
            run={run}
            seed={seed}
            unity={simulation.useUnity}
            showFeedbackToolbar={false}
            exit={exit}
        />
    )
}

export function SimulationPlayer({
    sim,
    run,
    seed,
    unity,
    showFeedbackToolbar,
    exit,
}: {
    sim: GetSimulationDto
    run: Run
    seed: number
    unity: boolean
    showFeedbackToolbar: boolean
    exit: (shouldConfirm: boolean, personalityTypes?: string[]) => void
}) {
    const locale = useCurrentLocale()
    const localize = useLocalize()

    const [paused, setPaused] = useState(false)
    const isPausedRef = useRef(paused)
    isPausedRef.current = paused
    const [score, setScore] = useState<number | undefined>(undefined)
    const [success, setSuccess] = useState<boolean | undefined>(undefined)

    const [showScoreScreen, setShowScoreScreen] = useState(false)
    const [selectPersonalityType, setSelectPersonalityType] = useState(false)
    const personalityTypes = useRef(
        /** The default personality types, until a script calls
         * `setPersonalityTypes()` to replace them */
        ["ENTJ", "INTJ", "INTP"]
    )

    async function saveGame() {
        await putRunSavedGame(run.id, state.saveGame())
    }
    useInterval(useCallback(saveGame, []), 15000)

    const state = useStateful((invalidate) => {
        // Need to capture this in the closure, otherwise it will be stale
        let _score: number | undefined
        const st = Simulate(sim, run, seed, {
            invalidate,
            speak,
            precacheAudio,
            async actionPerformed(action) {
                await postRunAction(run.id, {
                    action,
                    savedGame: state.saveGame(),
                })
            },
            setScore(score, success) {
                _score = score
                setScore(score)
                setSuccess(success)
                void postRunScore(run.id, {
                    score,
                    success: !!success,
                })
            },
            setPersonalityTypes(types) {
                personalityTypes.current = types
            },
            async end(options) {
                if (_score !== undefined) {
                    setSelectPersonalityType(options?.selectPersonalityType ?? false)
                    setShowScoreScreen(true)
                } else {
                    // setScore was never called, success by default
                    await postRunScore(run.id, {
                        success: true,
                    })
                    exit(
                        false,
                        options?.selectPersonalityType ? personalityTypes.current : undefined
                    )
                }
            },
        })

        // When running without unity we don't need to wait for load.
        if (!unity) st.start()
        return st
    })
    const [feedbackText, setFeedbackText] = useState("")

    /*useChangeNotifications(["Simulations", "Characters", "Environments"], async () => {
        const newSim = await getSimulation(sim.id)
        Object.assign(sim, newSim)
        state.hotReload(sim)
    })*/

    function precacheAudio(hashes: string[]) {
        const root = document.getElementById("root")

        hashes = hashes.filter((x) => {
            const res = !preloadedHashes.has(x)
            preloadedHashes.add(x)
            return res
        })

        const urls = hashes.map(audioHashToUrl)

        if (urls.length) {
            SendUnityMessage("PreCacheAudioFile", {
                ID: Uuid(),
                assets: urls,
            })
        }
    }

    function audioHashToUrl(hash: string) {
        return `${server()}/api/audio/${sim.id}/${hash}.mp3`
    }

    function speak(
        characterId: Character["id"] | "Player",
        hash: string,
        emotion: string | undefined,
        callbacks: { started: () => void; finished: () => void }
    ) {
        const audioUrl = audioHashToUrl(hash)

        if (unity) {
            if (emotion) {
                SetUnityState({
                    actor: characterId,
                    type: "emotion",
                    target: [emotion],
                })
            }

            SetUnityState(
                {
                    actor: characterId,
                    type: "say",
                    target: [audioUrl],
                },
                ({ state }) => {
                    if (state === "Started") callbacks.started()
                    if (state === "Finished") callbacks.finished()
                }
            )
            audioCallback = callbacks.finished
        } else {
            audio = new Audio(audioUrl)

            const playIt = () => {
                callbacks.started()
                if (!isPausedRef.current) void audio?.play()
                audio?.addEventListener("pause", callbacks.finished)
                audioCallback = callbacks.finished
            }

            audio.addEventListener("canplaythrough", playIt)
        }
    }

    const [feedbackOpen, setFeedbackOpen] = useState(false)

    function pause() {
        if (audioCallback) audio?.removeEventListener("pause", audioCallback)
        audio?.pause()
        setPaused(true)
        SendUnityMessage("TogglePause", {})
    }

    function resume() {
        if (audioCallback) audio?.addEventListener("pause", audioCallback)
        void audio?.play()
        setPaused(false)
        SendUnityMessage("TogglePause", {})
    }
    const [showSelectAnOption, setShowSelectAnOption] = useState(false)
    function goNext() {
        if (state.options) {
            setShowSelectAnOption(true)
            setTimeout(() => setShowSelectAnOption(false), 3000)
            return
        }
        if (state.info) {
            state.info.continue()
            return
        }

        if (unity) {
            if (audioCallback) audioCallback()
        } else {
            if (paused) {
                if (audioCallback) audioCallback()
            } else audio?.pause()
        }
    }

    function goBack(s?: StepDto) {
        if (audioCallback) {
            audio?.removeEventListener("pause", audioCallback)
        }
        audio?.pause()
        state.goBackTo(s)
    }

    const emotion = state.say
        ? "emotion" in state.say.dialogLine
            ? state.say.dialogLine.emotion
            : "prompt" in state.say.dialogLine
              ? state.say.dialogLine.prompt?.emotion
              : undefined
        : undefined

    const sayProxy = {
        get text() {
            return state.say
                ? "text" in state.say.dialogLine
                    ? state.say.dialogLine.text
                    : "prompt" in state.say.dialogLine
                      ? state.say.dialogLine.prompt?.text
                      : undefined
                : undefined
        },
        set text(value) {
            if (value && state.say && "text" in state.say.dialogLine) {
                state.say.dialogLine.text = value
            } else if (
                value &&
                state.say &&
                "prompt" in state.say.dialogLine &&
                state.say.dialogLine.prompt &&
                "text" in state.say.dialogLine.prompt
            ) {
                state.say.dialogLine.prompt.text = value
            } else {
                alert("Cannot set text")
            }
        },
    }

    return (
        <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                    flexDirection: "column",
                    display: "flex",
                    flex: 2,
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        flex: 1,
                        backgroundColor: "#444",
                        backgroundImage: state.backgroundImage?.valueOf(),
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                    }}
                >
                    {showScoreScreen && (
                        <ScoreScreen
                            score={score ?? 0}
                            scoreMax={sim.maxScore}
                            success={success !== undefined ? success : true}
                            choices={state.computeChoices()}
                            infoText={sim.scoreInfo}
                            exit={() =>
                                exit(
                                    false,
                                    selectPersonalityType ? personalityTypes.current : undefined
                                )
                            }
                        />
                    )}
                    <div style={{ position: "relative", flex: 1 }}>
                        <ExitButton
                            onClick={async () => {
                                await saveGame()
                                exit(
                                    false,
                                    selectPersonalityType ? personalityTypes.current : undefined
                                )
                            }}
                        />

                        <ControlButtons
                            paused={paused}
                            togglePause={() => (paused ? resume() : pause())}
                        />

                        {feedbackOpen && (
                            <div
                                style={{
                                    flex: 1,
                                    zIndex: 100,
                                    position: "absolute",
                                    top: 100,
                                    left: 100,
                                }}
                            >
                                <div
                                    style={{
                                        margin: 32,
                                        background: "white",
                                        maxWidth: 500,
                                        padding: 32,
                                        borderRadius: 16,
                                        boxShadow: "8px 8px 8px #0007",
                                    }}
                                >
                                    <div style={{ fontWeight: "bold" }}>Give feedback</div>
                                    <div>
                                        <div style={{ marginTop: 16, marginBottom: 16 }}>
                                            Please enter your feedback for this part of the
                                            simulation:
                                        </div>
                                        <textarea
                                            value={feedbackText}
                                            onChange={(ev) => setFeedbackText(ev.target.value)}
                                            style={{
                                                borderRadius: 8,
                                                width: "100%",
                                                height: 150,
                                                padding: 16,
                                                borderColor: "#bbb",
                                            }}
                                        ></textarea>
                                        <div style={{ marginTop: 16, marginBottom: 16 }}>
                                            Your feedback will help us improve the content before
                                            final production. Thank you for your help!
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <RButton
                                                variant="primary"
                                                style={{ marginRight: 16 }}
                                                onClick={async () => {
                                                    try {
                                                        await postFeedback({
                                                            feedback: Markdown(feedbackText),
                                                            scene: state.scene.id,
                                                            seed,
                                                            simulation: sim.id,
                                                            step: state.steps[
                                                                state.steps.length - 1
                                                            ].id,
                                                        })
                                                        setFeedbackText("")
                                                        alert(
                                                            "Thank you! Your feedback has been received "
                                                        )

                                                        resume()
                                                        setFeedbackOpen(false)
                                                    } catch (error) {
                                                        alert(
                                                            "We are sorry, but something went wrong while submitting your feedback. If the problem persists, please submit the feedback by email."
                                                        )
                                                    }
                                                }}
                                            >
                                                Submit
                                            </RButton>
                                            <RButton
                                                variant="danger"
                                                onClick={() => {
                                                    resume()
                                                    setFeedbackOpen(false)
                                                }}
                                            >
                                                Cancel
                                            </RButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {state.info && (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <div style={{ flex: 1 }} />
                                <div
                                    style={{
                                        position: "relative",
                                        background: "#fffe",
                                        boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                                        padding: 32,
                                        paddingLeft: 64,
                                        paddingRight: 64,
                                        borderRadius: 32,
                                        fontSize: 24,
                                        display: "flex",

                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        zIndex: 1,
                                        maxHeight: "60%",
                                        width: "60%",
                                        maxWidth: 1100,
                                    }}
                                >
                                    <div style={{ height: "100%", overflowY: "auto" }}>
                                        <MarkdownView value={localize(state.info.narrative.text)} />

                                        <div style={{ height: 100 }} />
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            borderRadius: 32,
                                            // A linear gradient from transparent in the top to white at the bottom
                                            background:
                                                "linear-gradient(rgba(255,255,255,0) 20%, white 50%)",
                                            height: 200,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button text="Continue" onClick={state.info.continue} />
                                    </div>
                                </div>
                                <div style={{ flex: 1 }} />
                            </div>
                        )}
                        {state.options && (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    padding: 16,
                                    borderRadius: 32,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    flexDirection: "column",

                                    zIndex: 1,
                                }}
                            >
                                {showSelectAnOption && <SelectAnOptionFlash />}
                                {state.options
                                    .filter((o) => o.enabled)
                                    .map(({ dialogOption, onClick }) => (
                                        <OptionItem
                                            onClick={onClick}
                                            dialogColor={
                                                sim.colorFeedbackOnChoices
                                                    ? dialogOption.color
                                                    : "Neutral"
                                            }
                                            obj={{
                                                get text() {
                                                    return localize(dialogOption.text).valueOf()
                                                },
                                                set text(v) {
                                                    dialogOption.text[locale as any] = Markdown(v)
                                                },
                                            }}
                                        />
                                    ))}
                            </div>
                        )}
                    </div>
                    {state.say && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "flex-end",
                                background: "#0006",
                                padding: 32,
                                zIndex: 1,
                            }}
                        >
                            <div
                                style={{
                                    maxWidth: 1024,
                                    fontSize: 24,
                                    fontWeight: "normal",
                                    color: "#fff",
                                    textAlign: "center",
                                    textShadow: "0px 0px 1px #000000, -0px -0px 3px #000000",
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                {state.say.avatar && (
                                    <div
                                        style={{
                                            display: "inline",
                                            backgroundImage: state.say.avatar.valueOf(),
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            width: 48,
                                            minWidth: 48,
                                            height: 48,
                                            minHeight: 48,
                                            borderRadius: 32,
                                            margin: 2,
                                            marginRight: 16,
                                        }}
                                    />
                                )}
                                <span>
                                    {state.say.name && (
                                        <span style={{ fontWeight: "bold", marginRight: 10 }}>
                                            {state.say.name}:
                                        </span>
                                    )}
                                    {sayProxy.text ? localize(sayProxy.text) : undefined}
                                </span>
                            </div>
                        </div>
                    )}

                    {unity && <Unity key="unity" unityReady={state.start} dataset={sim.dataset} />}
                </div>

                {showFeedbackToolbar && (
                    <FeedbackToolbar
                        canNavigate={!!state.options}
                        pause={pause}
                        resume={resume}
                        paused={paused}
                        choices={state.choices}
                        goNext={goNext}
                        goBack={goBack}
                        sim={sim}
                        steps={state.steps}
                        openFeedbackBox={() => setFeedbackOpen(true)}
                        evaluateCondition={state.evaluateCondition}
                    />
                )}
            </div>
            {state.reflection && (
                <ReflectionView
                    reflection={state.reflection.reflection}
                    goNext={state.reflection.continue}
                />
            )}
        </div>
    )
}

function SelectAnOptionFlash() {
    const [flash, setFlash] = useState(0)
    requestAnimationFrame(() => setFlash(Date.now()))
    return (
        <div
            style={{
                backgroundColor: "black",
                padding: 8,
                color: "#0F0",
                fontSize: 20,
                borderRadius: 8,
                transform: `scale(${1 + Math.sin(flash / 100) * 0.03})`,
                display: "flex",
                flexDirection: "row",
            }}
        >
            <div
                style={{
                    transform: `rotate(-90deg)`,
                    marginRight: 0,
                    marginTop: -8,
                    marginLeft: 8,
                }}
            >
                <ArrowLeft hover={false} />
            </div>
            Select an option to continue the game
        </div>
    )
}

function OptionItem({
    onClick,
    obj,
    dialogColor,
}: {
    onClick: () => void
    obj: { text: string }
    dialogColor: DialogOption["color"]
}) {
    const { hover, hoverProps } = useHover()

    const { fire, value: flash } = useEnvelope({
        attack: 50,
        attackExponent: 0.5,
        release: 1275,
        releaseExponent: 2,
        cutoff: 500,
        whenDone: onClick,
    })

    const color = hover ? "#cef" : "white"

    const flashColor =
        dialogColor === "Green"
            ? correctGreen
            : dialogColor === "Yellow"
              ? darkMustard
              : dialogColor === "Red"
                ? "#F22"
                : color

    return (
        <div
            {...hoverProps}
            onClick={fire}
            style={{
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 12,
                paddingBottom: 12,
                boxShadow: "rgb(38, 57, 77) 0px 10px 10px -10px",
                width: "30%",
                borderRadius: 32,
                margin: 8,
                transform: hover
                    ? `scale(${
                          1.05 +
                          ((dialogColor === "Green" || dialogColor === "Neutral") &&
                          flash !== undefined
                              ? flash * 0.05
                              : 0)
                      }) translateX(-15px) rotateZ(${
                          flash === undefined
                              ? 0
                              : Math.sin(15 * flash) *
                                (dialogColor === "Red" ? 2.5 : dialogColor === "Yellow" ? 1.0 : 0)
                      }deg)`
                    : undefined,
                transition: flash !== undefined ? undefined : "all 0.215s ease",
                background: flash !== undefined ? interpolate([color, flashColor])(flash) : color,
                cursor: "pointer",
            }}
        >
            {obj.text}
        </div>
    )
}

function ControlButtons(props: { togglePause: () => void; paused: boolean }) {
    return (
        <div
            style={{
                position: "absolute",
                cursor: "pointer",
                top: 16,
                right: 16,
                zIndex: 10,
                backgroundColor: "#131D22B6",
                padding: 8,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 12,
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
            }}
        >
            <PauseButton onClick={props.togglePause} paused={props.paused} />
        </div>
    )
}

function PauseButton(props: { onClick: () => void; paused: boolean }) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            onClick={props.onClick}
            {...hoverProps}
            style={{
                fontWeight: 400,
                color: hover ? darkMustard : "white",
                borderRadius: 12,
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                transform: hover ? "scale(0.95)" : "scale(0.8)",
                transition: "all 0.165s ease",
            }}
        >
            {props.paused ? (
                <div key="play">
                    <svg
                        width="25"
                        height="28"
                        viewBox="0 0 25 28"
                        fill={hover ? darkMustard : "white"}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M22.7214 11.4395C23.6215 11.9896 24.1691 12.9585 24.1691 14.0024C24.1691 15.0463 23.6215 16.0152 22.7214 16.5091L4.59653 27.5109C3.66376 28.1297 2.49621 28.1547 1.5433 27.6234C0.590191 27.0921 0 26.0919 0 25.0042V3.00056C0 1.91538 0.590191 0.914588 1.5433 0.38325C2.49621 -0.147463 3.66376 -0.125584 4.59653 0.440135L22.7214 11.4395Z" />
                    </svg>
                </div>
            ) : (
                <div key="pause">
                    <svg
                        width="23"
                        height="30"
                        viewBox="0 0 23 30"
                        fill={hover ? darkMustard : "white"}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M19.2334 0L16.9706 0C15.0961 0 13.5765 1.67498 13.5765 3.67108V26.1185C13.5765 28.1847 15.0961 29.8597 16.9706 29.8597L19.2334 30C21.1079 30 22.6275 28.325 22.6275 26.2588V3.81138C22.6275 1.74513 21.1072 0 19.2334 0ZM5.65687 0L3.39412 0C1.51958 0 0 1.67498 0 3.74123L0 26.1886C0 28.3242 1.51958 30 3.39412 30H5.65687C7.53141 30 9.05099 28.325 9.05099 26.2588V3.81138C9.05099 1.74513 7.53071 0 5.65687 0Z" />
                    </svg>
                </div>
            )}
        </div>
    )
}
