import React, { useState } from "react"
import { useNavigate } from "../../../packages/hooks/useNavigate"
import { PersonalityType, postPersonalityTypeResponse, usePersonalityTypes } from "./client"
import { darkMustard, yellowBG } from "./colors"
import { SaveAndCloseButton } from "./components"
import { ExitButton } from "./ExitButton"
import { CheckCircle } from "./icons"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"

export function SelectPersonalityTypeView(props: {
    personalityTypeCandidates: string[]
    exit: () => void
}) {
    const navigate = useNavigate()
    const [tabIndex, setTabIndex] = useState(0)
    const [showReport, setShowReport] = useState(false)
    const [selectedType, setSelectedType] = useState<PersonalityType | undefined>(undefined)
    const personalityTypes = usePersonalityTypes()
    if (!personalityTypes.data) return <div>Loading...</div>

    const tabs = [...props.personalityTypeCandidates, "Select Your Type"]
    const currentType = personalityTypes.data.find(
        (pt) => pt.key === props.personalityTypeCandidates[tabIndex]
    )

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: yellowBG,
                position: "relative",
                overflow: "hidden",
            }}
        >
            <ExitButton onClick={props.exit} />
            <div
                style={{
                    overflowY: "auto",
                    width: "100%",
                    height: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 32,
                }}
            >
                {showReport && selectedType && (
                    <>
                        <div style={{ fontSize: 36, fontWeight: 700, marginTop: 64 }}>
                            Great, you have selected {selectedType.key}!
                        </div>
                        <div style={{ margin: "0 auto", marginTop: 80 }}>
                            <CheckCircle />
                        </div>
                        <div style={{ fontSize: 18, fontWeight: 400, marginTop: 73 }}>
                            The choice has been saved to your profile. If you regret it you can
                            always redo the Type Tester.
                        </div>
                        <div style={{ fontSize: 18, fontWeight: 400, marginTop: 24 }}>
                            Click on the link below to read the report about {selectedType.key}s
                            now. If you don't have time, the report can also be found under your
                            profile later.
                        </div>
                        {selectedType.fullTypeReport && (
                            <div style={{ fontSize: 18, fontWeight: 700, marginTop: 24 }}>
                                <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(`/articles/${selectedType.fullTypeReport}`)
                                    }
                                >
                                    <span style={{ textDecoration: "underline" }}>
                                        Read the {selectedType.key} Report now
                                    </span>{" "}
                                    <RightArrow />
                                </span>
                            </div>
                        )}
                        <div style={{ position: "absolute", bottom: 92 }}>
                            <SaveAndCloseButton text={"CLOSE"} onClick={() => props.exit()} />
                        </div>
                    </>
                )}
                {!showReport && (
                    <>
                        <div style={{ fontSize: 36, fontWeight: 700, marginTop: 64 }}>
                            Your Top 3 Personality Types
                        </div>
                        <div style={{ fontSize: 18, fontWeight: 400, marginTop: 16 }}>
                            {"Explore all three, and then select the one you find most fitting."}
                        </div>
                        <div
                            style={{
                                fontSize: 18,
                                fontWeight: 400,
                                marginTop: 16,
                                fontStyle: "italic",
                            }}
                        >
                            {"Finish your selection before you return or exit."}
                        </div>
                        <div
                            style={{
                                marginTop: 48,
                                marginBottom: 66,
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            {tabs.map((tab, i) => (
                                <div
                                    key={tab}
                                    onClick={() => setTabIndex(i)}
                                    style={{
                                        margin: 15,
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        paddingTop: 15,
                                        paddingBottom: 15,
                                        transition: "all 0.2s",
                                        color: i === tabIndex ? darkMustard : "#545656",
                                        cursor: "pointer",
                                        borderRadius: 12,
                                        backgroundColor: i === tabIndex ? "#FCF1E1" : undefined,
                                        fontSize: 18,
                                        fontWeight: i === tabIndex ? 700 : 600,
                                    }}
                                >
                                    {i < tabs.length - 1 ? i + 1 + ". " + tab : tab}
                                </div>
                            ))}
                        </div>
                        {tabIndex < tabs.length - 1 && currentType && (
                            <>
                                <PersonalityTypeHeader personalityType={currentType} />
                                <div
                                    style={{
                                        marginTop: 64,
                                        maxWidth: 1024,
                                        paddingRight: 48,
                                        paddingLeft: 48,
                                    }}
                                >
                                    <MarkdownView
                                        value={
                                            currentType.description ?? "No description provided."
                                        }
                                    />
                                </div>
                            </>
                        )}
                        {tabIndex === tabs.length - 1 && (
                            <>
                                {props.personalityTypeCandidates.map((s) => {
                                    const pt =
                                        personalityTypes.data &&
                                        personalityTypes.data.find((x) => x.key === s)
                                    if (!pt)
                                        return (
                                            <div>
                                                Something went wrong. Missing the top personality
                                                types.
                                            </div>
                                        )
                                    const { key, name, summary } = pt

                                    return (
                                        <div
                                            key={key}
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: "white",
                                                padding: 24,
                                                borderRadius: 12,
                                                marginBottom: 12,
                                                width: "100%",
                                                maxWidth: 700,
                                                border: "2px solid #FAF3E7",
                                                boxShadow: "0px 4px 20px rgba(191, 189, 176, 0.16)",
                                            }}
                                            onClick={() => setSelectedType(pt)}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    {pt === selectedType ? (
                                                        <Checked />
                                                    ) : (
                                                        <Unchecked />
                                                    )}
                                                </div>
                                                <div style={{ flex: 1, marginLeft: 16 }}>
                                                    <div
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        {key} - {name}
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            marginTop: 4,
                                                        }}
                                                    >
                                                        {summary}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {selectedType && (
                                    <div style={{ position: "absolute", bottom: 92 }}>
                                        <SaveAndCloseButton
                                            onClick={async () => {
                                                await postPersonalityTypeResponse(
                                                    selectedType.key,
                                                    props.personalityTypeCandidates
                                                )
                                                setShowReport(true)
                                            }}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

function PersonalityTypeHeader(props: { personalityType: PersonalityType }) {
    const { key, name } = props.personalityType
    return (
        <>
            <div style={{ fontSize: 48, fontWeight: 600 }}>{key}</div>
            <div style={{ marginTop: 24 }}>{name}</div>
            <div style={{ marginTop: 8 }}>
                {key.split("").map((letter, i) => {
                    return (
                        <span key={letter}>
                            <strong>{letter}</strong> = {letter2word(letter)}
                            {i < 3 && ", "}
                        </span>
                    )
                })}
            </div>
        </>
    )

    function letter2word(letter: string) {
        switch (letter) {
            case "I":
                return "Introverting"
            case "E":
                return "Extroverting"
            case "N":
                return "iNtuiting"
            case "S":
                return "Sensing"
            case "T":
                return "Thinking"
            case "F":
                return "Feeling"
            case "J":
                return "Judging"
            case "P":
                return "Perceiving"
        }
    }
}

function Unchecked() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="26" height="26" rx="13" stroke="#B0B9BF" strokeWidth="2" />
        </svg>
    )
}

function Checked() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="1" y="1" width="26" height="26" rx="13" stroke="#E69A1C" strokeWidth="2" />
            <circle cx="14" cy="14" r="7" fill="#E69A1C" />
        </svg>
    )
}

function RightArrow() {
    return (
        <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.5384 10.6033C22.1522 9.99292 22.1522 9.00171 21.5384 8.39136L13.6812 0.578857C13.0674 -0.0314941 12.0705 -0.0314941 11.4567 0.578857C10.8429 1.18921 10.8429 2.18042 11.4567 2.79077L16.6375 7.93726H1.57143C0.702232 7.93726 0 8.6355 0 9.49976C0 10.364 0.702232 11.0623 1.57143 11.0623H16.6326L11.4616 16.2087C10.8478 16.8191 10.8478 17.8103 11.4616 18.4207C12.0754 19.031 13.0723 19.031 13.6862 18.4207L21.5433 10.6082L21.5384 10.6033Z"
                fill="#E69A1C"
            />
        </svg>
    )
}
