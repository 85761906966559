import React, { useState } from "react"

export function PartialCircle(props: {
    offset?: number
    radius: number
    percent: number
    strokeWidth: number
    color?: string
    animate: boolean
}) {
    const [percent, setPercent] = useState(props.animate ? 0 : props.percent)

    if (Math.abs(props.percent - percent) > 0.1) {
        if (props.animate) {
            requestAnimationFrame(() => {
                setPercent(percent + (props.percent - percent) / 10)
            })
        } else {
            {
                setPercent(props.percent)
            }
        }
    }

    const circumference = props.radius * 2 * Math.PI
    const arc = circumference * (percent / 100)
    const dashArray = `${arc} ${circumference}`
    const offset = props.offset ?? props.strokeWidth * 0.5
    const x = props.radius + offset
    const y = props.radius + offset
    return (
        <circle
            cx={x}
            cy={y}
            r={props.radius}
            fill="none"
            stroke={props.color ?? "black"}
            strokeWidth={props.strokeWidth}
            transform={`rotate(-90, ${x} ${y})`}
            strokeDasharray={dashArray}
        />
    )
}
