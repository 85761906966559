import { Uuid } from "../../../reactor/Types/Primitives"
import { usePreference } from "../../../reactor/Web"

export type LocalSession = {
    sessionCode: string
    name: string
    department?: string
}

export function useLocalSession(moduleId: Uuid<"Module">) {
    return usePreference("session" + moduleId.valueOf(), undefined as LocalSession | undefined)
}
