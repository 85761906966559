import React from "react"
import { darkMustard } from "./colors"

export function ProgressBar(props: { percent: number; text: string; children: JSX.Element }) {
    return (
        <div
            style={{
                backgroundColor: "white",
                padding: 16,
                paddingLeft: 32,
                paddingRight: 32,
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 2.5px 7.5px",
                marginTop: 96,
                borderRadius: 8,
                width: "100%",
                maxWidth: 800,
                marginBottom: 128,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    flex: 1,
                    height: 5,
                    borderRadius: 2.5,
                    backgroundColor: "#EEE",
                    width: "100%",
                    position: "relative",
                }}
            >
                <div
                    style={{
                        height: 5,
                        borderRadius: 2.5,
                        backgroundColor: darkMustard,
                        width: `${props.percent}%`,
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        backgroundColor: darkMustard,
                        width: 12,
                        height: 12,
                        borderRadius: 6,
                        top: -4,
                        left: `${props.percent - 0.5}%`,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#423F3F",
                            position: "absolute",
                            top: -64,
                            whiteSpace: "nowrap",
                            borderRadius: 8,
                            left: "-550%",
                            color: "white",
                            paddingTop: 8,
                            paddingBottom: 8,
                            padding: 16,
                            fontWeight: "bold",
                        }}
                    >
                        <div>{props.text}</div>
                    </div>
                </div>
            </div>
            {props.children}
        </div>
    )
}
