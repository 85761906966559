import React, { useEffect, useRef } from "react"
import { ellipsize } from "../../../reactor/Helpers"
import { Character, DialogColor, StepDto } from "./client"
import { Markdown, Url, Uuid } from "../../../reactor/Types/Primitives"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { ColorStyles } from "../../../packages/ui"

const colors = {
    Green: "#449042",
    Yellow: "#E8B842",
    Red: "#C01F15",
    Neutral: "#116E8D",
} as const

export function StepView({
    step,
    characters,
    isCurrent,
    choices,
    onClick,
    evaluateScript,
}: {
    step: StepDto
    characters: Character[]
    isCurrent: boolean
    choices: Uuid<"DialogOption">[]
    onClick: () => void
    evaluateScript: (s: string) => any
}) {
    const localize = useLocalize()
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (isCurrent && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" })
        }
    }, [ref, isCurrent])

    let text: Markdown | undefined
    let image: Url | undefined
    let badges: Markdown[] | undefined
    let badgeColor: (DialogColor | null)[] | undefined
    let optionEnabled: boolean[] | undefined
    switch (step.type) {
        case "Say":
            image = characters.find((c) => c.id === step.character)?.portrait
            text = localize(step.text)
            break
        case "Dialog choice":
            if (step.prompt) {
                image = characters.find((c) => c.id === step.prompt?.character)?.portrait
                text = localize(step.prompt.text)
                badges = step.options.map((o) => localize(o.text))
                badgeColor = step.options.map((o) =>
                    choices.some((c) => o.uuid === c) ? o.color : null
                )
                optionEnabled = step.options.map((o) =>
                    o.condition ? evaluateScript(o.condition) : true
                )
            }
    }

    return (
        <div>
            <div
                onClick={onClick}
                ref={ref}
                style={{
                    borderRadius: 32,
                    backgroundColor: isCurrent ? "white" : "#ddd",
                    borderWidth: isCurrent ? 2 : 0,
                    borderStyle: "solid",
                    borderColor: ColorStyles.primary[500],
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: 8,
                    minWidth: 250,
                }}
            >
                {image && (
                    <div
                        style={{
                            backgroundImage: image ? `url(${image})` : undefined,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: 48,
                            minWidth: 48,
                            height: 48,
                            minHeight: 48,
                            borderRadius: 32,
                            margin: 2,
                        }}
                    />
                )}
                <div style={{ margin: 8 }}>{text ? ellipsize(text.valueOf(), 25) : step.type}</div>
            </div>
            {badges?.map((b, i) => {
                const y = i * 32 + 35
                const color = badgeColor && badgeColor[i]
                return (
                    <div
                        style={{
                            marginTop: 2,
                            marginLeft: 32,
                            backgroundColor: color ? colors[color] : "#ddd",
                            padding: 4,
                            borderRadius: 16,
                            fontSize: 14,
                            maxWidth: 200,
                            paddingLeft: 32,
                            opacity: !optionEnabled || optionEnabled[i] ? 1 : 0.5,
                        }}
                    >
                        {color && (
                            <svg
                                style={{
                                    zIndex: 10,
                                    clipPath: "none",
                                    float: "left",
                                    display: "block",
                                    overflow: "visible",
                                    height: 1,
                                }}
                            >
                                <path
                                    d={`M 168 10 C 180 10 200 -${y} 220 -${y}}`}
                                    stroke="#ddd"
                                    strokeWidth={4}
                                    fill="none"
                                />
                            </svg>
                        )}
                        {ellipsize(b.valueOf(), 20)}
                    </div>
                )
            })}
        </div>
    )
}
