import { BadRequest } from "../../../reactor/ErrorCodes"
import { OpaqueString } from "../../../reactor/Types/Opaque"
import { Uuid } from "../../../reactor/Types/Primitives"

/** A code that can be generated on the client while offline that generates a
 * (hopefully) unique code to identify the session, so play data can be
 * aggregated later when the players come online.
 *
 * The session code is on the form `000-000-11`
 *
 * Where:
 * - `000-000` is a random number
 * - `11` is a decimal hash of the module ID and random number, computed by
 *   taking the `computeSessionHash` function.
 *
 */
export type SessionCode = OpaqueString<"SessionCode">

/** Generates a new or validates an existing session code.
 *
 *  If called with one argument, a new code is generated for the given module code
 *  If an existing code is provided as second argument, an error is thrown if the code is invalid.
 */
export function SessionCode(
    moduleId: Uuid<"Module">,
    sessionCode?: string | SessionCode
): SessionCode {
    sessionCode ??= generateSessionCode(moduleId)
    validateSessionCode(moduleId, sessionCode as any)
    return sessionCode as any
}

export function generateSessionCode(moduleId: Uuid<"Module">) {
    const randomNumber = Math.floor(Math.random() * 1000000)
        .toString()
        .padStart(6, "0")

    const randomPart = `${randomNumber.slice(0, 3)}-${randomNumber.slice(3)}`

    const hash = computeSessionHash(moduleId, randomPart)
    return randomPart + "-" + hash
}

export function isSessionCodeValid(moduleId: Uuid<"Module">, sessionCode: string) {
    try {
        validateSessionCode(moduleId, sessionCode)
        return true
    } catch (e) {
        return false
    }
}

export function validateSessionCode(moduleId: Uuid<"Module">, sessionCode: string) {
    const [part1, part2, hash] = sessionCode.split("-")
    if (sessionCode.length !== "000-000-11".length) {
        throw new BadRequest("Invalid session code length")
    }
    if (hash !== computeSessionHash(moduleId, sessionCode)) {
        throw new BadRequest("The code is invalid. Please check all digits")
    }
}

export function computeSessionHash(moduleId: Uuid<"Module">, code: string) {
    let sum = 0
    for (let i = 0; i < "000-000".length; i++) {
        sum += code.charCodeAt(i) * 1337 * (i + 1)
    }
    for (let i = 0; i < moduleId.length; i++) {
        sum += moduleId.charCodeAt(i) * 19 * (i + 1)
    }
    const decimal = sum % 100
    const twoDigitCode = decimal.toString().padStart(2, "0").toUpperCase()
    return twoDigitCode
}
