import React, { useRef, useState } from "react"
import {
    ActionCard,
    GetActionCardsDto,
    GetSessionDto,
    putSession,
    useActionCards,
    useSession,
} from "./client"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import { ActionCardBox } from "./FacilitatorStepCard"
import { useOutletContext, useParams } from "react-router-dom"
import { HomeContext } from "./Home"
import { LocalSession, useLocalSession } from "./useLocalSession"
import { SaveAndCloseButton } from "./components"
import {
    useCurrentLocale,
    useLocalize,
} from "../../../packages/localization/client-side/useLocalize"
import { Markdown } from "../../../reactor/Types/Primitives/Markdown"

export function ActionCardsPage() {
    const { id } = useParams()
    const { modules } = useOutletContext<HomeContext>()
    const mod = modules.find((m) => m.id.toString() === id)
    const [localSession] = useLocalSession(mod!.id)
    const actionCards = useActionCards(mod!.id)
    const session = useSession(mod?.id ?? null, localSession?.sessionCode.valueOf() ?? null)

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                height: "100%",
                backgroundColor: "#FAF3E7",
            }}
        >
            {localSession && actionCards.data && session.data && mod && (
                <ActionCardsSelector
                    localSession={localSession}
                    actionCardsData={actionCards.data}
                    sessionData={session.data}
                    moduleColor={mod.color}
                    saveAndClose={async (sessionCode, body) => {
                        try {
                            await putSession(mod.id, sessionCode, body)
                            history.back()
                        } catch (e) {
                            alert(e)
                        }
                    }}
                />
            )}
        </div>
    )
}

function ActionCardsSelector({
    localSession,
    actionCardsData,
    sessionData,
    moduleColor,
    saveAndClose,
}: {
    localSession: LocalSession
    actionCardsData: GetActionCardsDto
    sessionData: GetSessionDto
    moduleColor: string
    saveAndClose: (
        sessionCode: string,
        body: {
            selectedActionCards: Uuid<"ActionCard">[]
            customActionCards: ActionCard[]
        }
    ) => void
}) {
    const localize = useLocalize()
    const locale = useCurrentLocale()

    const addActionCard: ActionCard = {
        title: { en: "Add Action Card" },
        body: { en: Markdown("A placeholder for adding more action cards.") },
        id: Uuid(),
        modules: [],
        tips: [],
    }
    const [customActionCards, setCustomActionCards] = useState<ActionCard[]>(
        sessionData.customActionCards ?? [
            {
                title: { en: "" },
                body: { en: Markdown("") },
                id: Uuid(),
                modules: [],
                tips: [],
            },
        ]
    )
    const [selectedActionCards, setSelectedActionCards] = useState(
        sessionData.selectedActionCards?.filter((aId) => {
            const exists =
                customActionCards.some((a) => a.id === aId) ||
                actionCardsData.actionCards.some((a) => a.id === aId)
            // Selected cards need to still exist for UI consistency
            if (!exists) console.warn(`Selected action card ${aId} has been removed from Reactor`)
            return exists
        }) ?? []
    )
    const selectedRef = useRef<HTMLSpanElement>(null)

    return (
        <div
            style={{
                flex: 1,
                padding: 32,
            }}
        >
            <div style={{ fontSize: 24 }}>
                Action Cards - <span ref={selectedRef}>{selectedActionCards.length}</span> selected
            </div>
            <div style={{ fontSize: 16, marginTop: 8 }}>
                Session:{" "}
                <strong>
                    {localSession.name} ({localSession.sessionCode})
                </strong>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 24,
                    marginBottom: 24,
                }}
            >
                {getColumns(3).map((column, i) => (
                    <div
                        key={i}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                        }}
                    >
                        {column.map(mapActionCard)}
                    </div>
                ))}
            </div>
            <SaveAndCloseButton
                onClick={() =>
                    saveAndClose(localSession.sessionCode, {
                        selectedActionCards,
                        customActionCards,
                    })
                }
            />
        </div>
    )

    function mapActionCard(actionCard: ActionCard) {
        return actionCard === addActionCard ? (
            <div
                key={actionCard.id.toString()}
                style={{
                    width: "100%",
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <span
                    style={{
                        fontSize: 64,
                        fontWeight: 700,
                        color: "silver",
                        cursor: "pointer",
                        padding: 16,
                    }}
                    onClick={() => {
                        const last = customActionCards[customActionCards.length - 1]
                        if (
                            customActionCards.length === 0 ||
                            (localize(last.title).length > 0 && localize(last.body).length > 0)
                        ) {
                            setCustomActionCards([
                                ...customActionCards,
                                {
                                    title: { en: "" },
                                    body: { en: Markdown("") },
                                    id: Uuid(),
                                    modules: [],
                                    tips: [],
                                },
                            ])
                        }
                    }}
                >
                    +
                </span>
            </div>
        ) : (
            <ActionCardBox
                key={actionCard.id.toString()}
                title={localize(actionCard.title)}
                description={localize(actionCard.body)}
                onSelect={(selected) => {
                    const index = selectedActionCards.indexOf(actionCard.id)
                    if (!selected && index !== -1) {
                        selectedActionCards.splice(index, 1)
                    } else if (selected && index === -1) {
                        selectedActionCards.push(actionCard.id)
                    }
                    selectedRef.current?.replaceChildren(selectedActionCards.length.toString())
                    setSelectedActionCards(selectedActionCards)
                }}
                selected={selectedActionCards.includes(actionCard.id)}
                selectedColor={moduleColor}
                canEdit={customActionCards.some((ac) => ac.id === actionCard.id)}
                onTitleChanged={(value) => (actionCard.title[locale as any] = value)}
                onDescriptionChanged={(value) =>
                    (actionCard.body[locale as any] = value as any as Markdown)
                }
                onRemove={() => {
                    const index = customActionCards.indexOf(actionCard)
                    if (index > -1) {
                        customActionCards.splice(index, 1)
                        setCustomActionCards([...customActionCards])

                        const selectedIndex = selectedActionCards.indexOf(actionCard.id)
                        if (selectedIndex > -1) {
                            selectedActionCards.splice(selectedIndex, 1)
                            setSelectedActionCards(selectedActionCards)
                        }
                    }
                }}
            />
        )
    }

    function getColumns(columnCount: number) {
        const actionCards = [...actionCardsData.actionCards, ...customActionCards, addActionCard]
        const columns: ActionCard[][] = []

        for (let i = 0; i < columnCount; i++) {
            columns.push([])
        }

        for (let i = 0; i < actionCards.length; i++) {
            columns[i % columnCount].push(actionCards[i])
        }

        return columns
    }
}
