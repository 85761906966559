import React from "react"
import { useHover } from "../../../packages/hooks/useHover"
import { darkMustard } from "./colors"

export function Button(props: { onClick: () => void; text: string }) {
    const { hover, hoverProps } = useHover()
    return (
        <div
            onClick={props.onClick}
            {...hoverProps}
            style={{
                margin: 32,
                width: 300,
                padding: 16,
                borderRadius: 8,
                backgroundColor: darkMustard,
                display: "flex",
                flexDirection: "row",
                justifyItems: "center",
                transform: hover ? "scale(1.05)" : "scale(1)",
                transition: "transform 0.2s",
                cursor: "pointer",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                justifyContent: "center",
            }}
        >
            {props.text}
        </div>
    )
}
