import { Route, Routes, useParams } from "react-router-dom"
import { LocalesProvider } from "../../../packages/localization/client-side/useLocalesContext"
import { CurrentLocaleProvider } from "../../../packages/localization/client-side/CurrentLocaleContext"
import { ModalProvider } from "../../../packages/modal/Modal"
import { LoginLinkRoute } from "../../../packages/ssr/Login"
import { SSRStatus } from "../../../packages/ssr/SSRStatus"
import { ActionCardsPage } from "./ActionCards"
import { ArticlePage } from "./ArticleView"
import { BadgesPage } from "./Badges"
import { useUserPrivileges } from "./client"
import { DashboardPage } from "./Dashboard"
import { FeedbackUtilityHome } from "./FeedbackUtility"
import { Home, ModulePage } from "./Home"
import { Leaderboard } from "./Leaderboard"
import { Missing } from "./Missing"
import { ProfilePage } from "./Profile"
import { ScreenplayView } from "./ScreenplayView"
import { VerifyEmailPage } from "./VerifyEmailPage"
import { Studio } from "../../../studio/Studio"
import { EditableSiteLazy } from "../../../packages/editing/EditableSiteLazy"
import { useAnalyticsLogger } from "../../../packages/analytics/useAnalyticsLogger"

export default function App() {
    useAnalyticsLogger()
    const { data: privileges } = useUserPrivileges()
    return (
        <ModalProvider>
            <LocalesProvider>
                <Routes>
                    {Studio()}
                    <Route path="/">
                        <Route path="playtester" element={<FeedbackUtilityHome />} />
                        {LoginLinkRoute({ path: "demo-auth", redirectUrl: "/" })}
                        <Route path="screenplay/:id" element={<ScreenplayView />} />
                        <Route path="verify" element={<VerifyEmailPage />} />
                    </Route>

                    <Route
                        path="/"
                        element={
                            <CurrentLocaleProvider>
                                <Home />
                            </CurrentLocaleProvider>
                        }
                    >
                        <Route index element={<DashboardPage />} />
                        <Route path="modules">
                            <Route index element={<ModulePage />} />
                            <Route path=":id">
                                <Route index element={<ModulePage />} />
                                <Route path="action-cards" element={<ActionCardsPage />} />
                                <Route
                                    path="individual"
                                    element={<ModulePage innerTab="Individual" />}
                                />
                                <Route
                                    path="facilitator"
                                    element={<ModulePage innerTab="Facilitator" />}
                                />
                            </Route>
                        </Route>
                        <Route path="scores" element={<Leaderboard />} />
                        <Route path="badges" element={<BadgesPage />} />
                        <Route path="profile" element={<ProfilePage />} />
                    </Route>
                    <Route
                        path="/"
                        element={<EditableSiteLazy condition={!!privileges?.canEditArticles} />}
                    >
                        <Route path="articles/:id" element={<ArticlePage />} />
                        <Route path="*" element={<NotFoundView />} />
                    </Route>
                </Routes>
            </LocalesProvider>
        </ModalProvider>
    )
}

export function NotFoundView() {
    SSRStatus.code = 404
    const path = useParams()["*"]
    return (
        <Missing
            error={{
                type: "Not Found",
                status: 404,
                detail: "There is no page at this location: '" + path + "'",
            }}
            data={undefined}
            loading={false}
        />
    )
}

// DUMMY - to make sure dev environment is not destroyed when other commits merge
