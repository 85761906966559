import type { QuizQuestionResponse, GetQuizDto } from "./client"

export function getQuizResponseScore(
    quiz: GetQuizDto,
    responses: QuizQuestionResponse[],
    maxScore = 350
) {
    const scores = responses
        .map((res): { correct: number; total: number } => {
            const q = quiz.steps.find((step) => step.id === res.question)
            if (q?.type === "Question") {
                if (q.isArrangeChallenge) {
                    if (q.options.type === "Strings") {
                        return {
                            correct: q.options.strings.every((s, i) => s.id === res.response[i])
                                ? 1
                                : 0,
                            total: 1,
                        }
                    } else if (q.options.type === "Images") {
                        return {
                            correct: q.options.images.every((s, i) => s.id === res.response[i])
                                ? 1
                                : 0,
                            total: 1,
                        }
                    } else {
                        throw new Error("Unknown options type")
                    }
                } else {
                    if (q.options.type === "Strings") {
                        return {
                            correct: q.options.strings.filter(
                                (x) => x.correct && res.response.includes(x.id)
                            ).length,
                            total: q.options.strings.filter((x) => x.correct).length,
                        }
                    } else if (q.options.type === "Images") {
                        return {
                            correct: q.options.images.filter(
                                (x) => x.correct && res.response.includes(x.id)
                            ).length,
                            total: q.options.images.filter((x) => x.correct).length,
                        }
                    } else {
                        throw new Error("Unknown options type")
                    }
                }
            }
            throw new Error("Invalid question")
        })
        .reduce((a, b) => ({ correct: a.correct + b.correct, total: a.total + b.total }), {
            correct: 0,
            total: 0,
        })

    return {
        score: Math.ceil((scores.correct / scores.total) * maxScore),
        maxScore,
    }
}
