import React from "react"
import { yellowBG } from "./colors"
import { ExitButton } from "./ExitButton"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"
import { ImageToUrl, VideoToUrl } from "../../../reactor"
import { ModuleStepVideoDto } from "../api"

export function VideoView({ step, exit }: { step: ModuleStepVideoDto; exit: () => void }) {
    const { title, description, video, thumbnail } = step
    const localize = useLocalize()
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: yellowBG,
                position: "relative",
                overflow: "hidden",
            }}
        >
            <ExitButton onClick={exit} />
            <div
                style={{
                    overflowY: "auto",
                    width: "100%",
                    height: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 96,
                }}
            >
                {title && <div style={{ fontSize: 22, fontWeight: 700 }}>{localize(title)}</div>}
                <video
                    src={VideoToUrl(video, {})}
                    poster={thumbnail && ImageToUrl(thumbnail, {})}
                    style={{ paddingTop: 24, maxHeight: 720 }}
                    controls
                >
                    Your browser does not support the video tag.
                </video>
                {description && <div style={{ paddingTop: 12 }}>{localize(description)}</div>}
            </div>
        </div>
    )
}
