import React, { useEffect, useRef } from "react"
import { AspectLockLetterbox } from "../../../packages/aspect-lock/AspectLock"
import { Uuid } from "../../../reactor/Types/Primitives/Uuid"
import { SetSendUnityMessageHandler, UnityCallback } from "./UnityController"

const callbacks = new Map<string, UnityCallback>()
export function Unity(props: { unityReady(): void; dataset: string }) {
    const ref = useRef<HTMLIFrameElement>(null)

    SetSendUnityMessageHandler((method, param, callback) => {
        const id = param.ID ?? Uuid()
        if (callback) callbacks.set(id.valueOf(), callback)
        const w = ref.current?.contentWindow

        const msg = {
            method,
            param: { ID: id, ...param },
        }

        if (w) {
            setTimeout(() => w.postMessage(msg, "*"), 1)
        }
    })

    useEffect(() => {
        function listener(e: MessageEvent) {
            if (e.data === "UNITY_READY") {
                props.unityReady()
            } else {
                try {
                    const msg = JSON.parse(e.data)
                    console.log("Received callback: " + e.data)
                    if (msg.type === "CALLBACK") {
                        const callback = callbacks.get(msg.param.ID)
                        if (callback) {
                            callback(msg.detail)

                            if (msg.detail.state === "Finished") {
                                callbacks.delete(msg.param.ID)
                            }
                        }
                    }
                } catch (error) {}
            }
        }
        window.addEventListener("message", listener)
        return () => window.removeEventListener("message", listener)
    }, [])

    return (
        <AspectLockLetterbox aspect={16 / 9} style={{ backgroundColor: "black" }}>
            {(width, height) => (
                <iframe
                    ref={ref}
                    src={`https://sayfr-static.fuseapps.no/${props.dataset}/index.html`}
                    style={{
                        // CSS trick to retain aspect ratio 16:9
                        border: "none",
                        width,
                        height,
                    }}
                />
            )}
        </AspectLockLetterbox>
    )
}
