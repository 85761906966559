import { useState } from "react"

export function useEnvelope(options: {
    attack: number
    attackExponent?: number
    release: number
    releaseExponent?: number
    /** If specified, the envelope cuts of aftr this many ms, even if it is not completely done
     * animating. */
    cutoff?: number
    whenDone: () => void
}) {
    const totalLength =
        options.cutoff !== undefined ? options.cutoff : options.attack + options.release

    const [start, setStart] = useState<number | undefined>(undefined)
    const [version, setVersion] = useState({})

    function fire() {
        if (start === undefined) {
            setStart(Date.now())
        }
    }

    const time = start === undefined ? undefined : Date.now() - start

    if (time !== undefined) {
        if (time > totalLength) {
            options.whenDone()
            setStart(undefined)
        } else {
            requestAnimationFrame(() => setVersion({}))
        }
    }

    function computeValue() {
        if (time === undefined) return 0
        if (time < options.attack)
            return Math.pow(time / options.attack, options.attackExponent ?? 1)
        if (time < totalLength)
            return Math.pow(
                1 - (time - options.attack) / options.release,
                options.releaseExponent ?? 1
            )
        return 0
    }

    return { fire, value: start !== undefined ? computeValue() : undefined }
}
