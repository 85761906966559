export type UnityCallback = (detail: { state?: "Started" | "Finished" }) => void

export type SendUnityMessage = (method: string, param: any, callback?: UnityCallback) => void

export type ScenarioDesc = {
    environmentName: string
    humans: {
        name: string
        headID: string
        bodyID: string
        eyeAccID?: string
        headAccID?: string
    }[]

    initialStates: {
        actor: string
        type: string
        target: string[]
    }[]
}

let sendMessage: SendUnityMessage | undefined

export const SendUnityMessage = (method: string, param: any, callback?: UnityCallback) => {
    if (!sendMessage) {
        // Not running with Unity, call back immediately
        if (callback) callback({ state: "Finished" })
        return
    }
    sendMessage(method, param, callback)
}

export const SetUnityState = (param: any, callback?: UnityCallback) => {
    SendUnityMessage("SetState", param, callback)
}

export function SetSendUnityMessageHandler(handler: SendUnityMessage) {
    sendMessage = handler
}
