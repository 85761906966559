import { useEffect } from "react"
import { useNavigate } from "../../../packages/hooks/useNavigate"

/** This hook redirects to another location if condition is true. */
export function useRedirect(location: string, condition?: boolean) {
    const navigate = useNavigate()
    useEffect(() => {
        if (condition) navigate(location)
    }, [condition])
}
