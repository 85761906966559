import React, { CSSProperties } from "react"
import { darkMustard } from "./colors"

export function SubmitButton(props: { onClick: () => void; style?: CSSProperties; text?: string }) {
    return (
        <div
            style={{
                backgroundColor: darkMustard,
                color: "white",
                textAlign: "center",
                cursor: "pointer",
                padding: 8,
                borderRadius: 16,
                width: 200,
                fontSize: 24,
                ...props.style,
            }}
            onClick={props.onClick}
        >
            {props.text ?? "SUBMIT"}
        </div>
    )
}
