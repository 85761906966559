import React, { useContext, useState } from "react"
import { Image } from "react-bootstrap"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { useOutletContext } from "react-router-dom"
import { useNavigate } from "../../../packages/hooks/useNavigate"
import { CurrentLocaleContext } from "../../../packages/localization/client-side/CurrentLocaleContext"
import { putUser, useKitLocales, usePersonalityType } from "./client"
import { HomeContext } from "./Home"
import { useLocalize } from "../../../packages/localization/client-side/useLocalize"

export function ProfilePage() {
    const { locale, setLocale } = useContext(CurrentLocaleContext)
    const localize = useLocalize()
    const { user, refresh } = useOutletContext<HomeContext>()
    const personalityType = usePersonalityType(user.personalityType ?? null)
    const navigate = useNavigate()
    const locales = useKitLocales()?.data
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                height: "100%",
                backgroundColor: "#FEFBF7",
                overflowY: "auto",
            }}
        >
            <div style={{ margin: 40, width: 690 }}>
                <div
                    style={{
                        flex: 1,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 20px 0px #BFBDB029",
                        border: "1px solid #FAF3E7",
                        borderRadius: 16,
                        marginBottom: 16,
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row", paddingTop: 0 }}>
                        <div
                            style={{
                                width: 120,
                                height: 120,
                                borderRadius: 120,
                                backgroundColor: "white",
                                position: "absolute",
                                zIndex: 1,
                                marginLeft: 24,
                                marginTop: 24,
                            }}
                        >
                            <div
                                style={{
                                    margin: 2,
                                    width: 116,
                                    height: 116,
                                    borderRadius: 116,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    backgroundColor: "#F1F5F8",
                                    color: "#131D22",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: 500,
                                    fontSize: 40,
                                }}
                            >
                                {user.initials}
                            </div>
                        </div>
                        <div style={{ marginLeft: 0, width: "100%" }}>
                            <div
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    borderTopLeftRadius: 16,
                                    borderTopRightRadius: 16,
                                    backgroundColor: "#083A50",
                                    paddingLeft: 156,
                                    paddingRight: 24,
                                    paddingTop: 64,
                                    paddingBottom: 6,
                                    color: "white",
                                }}
                            >
                                {user.company}
                                {user.department && (
                                    <>
                                        <span style={{ margin: 10 }}>|</span>
                                        {user.department}
                                    </>
                                )}
                                {user.subDepartment && (
                                    <>
                                        <span style={{ margin: 10 }}>|</span>
                                        {user.subDepartment}
                                    </>
                                )}
                            </div>
                            <div
                                style={{
                                    fontSize: 30,
                                    fontWeight: 500,
                                    paddingLeft: 156,
                                    paddingRight: 24,
                                    paddingTop: 6,
                                    paddingBottom: 16,
                                }}
                            >
                                {user.name}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", padding: 24 }}>
                        <div style={{ flex: 1 }}>
                            <div>
                                <div style={{ fontSize: 14, fontWeight: 700, marginTop: 0 }}>
                                    EMAIL
                                </div>
                                <div style={{ fontSize: 16, fontWeight: 400, marginTop: 8 }}>
                                    <a
                                        href={`mailto:${user.email}`}
                                        style={{ textDecoration: "underline", color: "#131D22" }}
                                    >
                                        {user.email}
                                    </a>
                                </div>
                            </div>
                            <div style={{ marginTop: 16 }}>
                                <div style={{ fontSize: 14, fontWeight: 700, marginTop: 8 }}>
                                    PHONE
                                </div>
                                <div style={{ fontSize: 16, fontWeight: 400, marginTop: 8 }}>
                                    {user.phoneNumber}
                                </div>
                            </div>
                            <div style={{ marginTop: 16 }}>
                                <div style={{ fontSize: 14, fontWeight: 700, marginTop: 8 }}>
                                    NATIONALITY
                                </div>
                                <div style={{ fontSize: 16, fontWeight: 400, marginTop: 8 }}>
                                    {user.nationality}
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: "center", minWidth: 240 }}>
                            <div style={{ fontSize: 14, fontWeight: 700 }}>PERSONALITY TYPE</div>
                            <div style={{ fontSize: 30, fontWeight: 500, margin: 4 }}>
                                {personalityType.data?.key ?? "-"}
                            </div>
                            <div style={{ fontSize: 16, fontWeight: 400 }}>
                                {personalityType.data?.name}
                            </div>
                            {personalityType.data?.fullTypeReport && (
                                <div
                                    onClick={() =>
                                        navigate(
                                            `/articles/${personalityType.data?.fullTypeReport}`
                                        )
                                    }
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 700,
                                        width: 240,
                                        height: 51,
                                        backgroundColor: "#083A50",
                                        borderRadius: 12,
                                        boxShadow: "0 4 20 rgba(191, 189, 176, 0.16)",
                                        color: "white",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: 33,
                                    }}
                                >
                                    Open Report
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        flex: 1,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 20px 0px #BFBDB029",
                        border: "1px solid #FAF3E7",
                        borderRadius: 16,
                        padding: 24,
                        marginBottom: 16,
                    }}
                >
                    <div style={{ fontSize: 22, fontWeight: 500 }}>Settings</div>
                    {user.isFacilitator && (
                        <div style={{ marginTop: 24, display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: 1 }}>
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 700,
                                        marginTop: 8,
                                    }}
                                >
                                    FACILITATOR
                                </div>
                                <div
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        marginTop: 8,
                                    }}
                                >
                                    Activate access to facilitator features
                                </div>
                            </div>
                            <Switch
                                value={user.isFacilitatorFeaturesEnabled}
                                onValueChanged={async (newValue) => {
                                    await putUser(user.id, {
                                        isFacilitatorFeaturesEnabled: newValue,
                                    })
                                    await refresh()
                                }}
                            />
                        </div>
                    )}
                    <div style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ fontSize: 14, fontWeight: 700, marginTop: 8 }}>
                                ANONYMOUS
                            </div>
                            <div style={{ fontSize: 16, fontWeight: 400, marginTop: 8 }}>
                                Appear as "anonymous" to other users in leaderboards.
                            </div>
                            <div
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginTop: 12,
                                    color: "#131D22",
                                    opacity: 0.7,
                                }}
                            >
                                <em>
                                    Your identity and playing data in Cultiv8
                                    <br />
                                    will still be visible to project management.
                                </em>
                            </div>
                        </div>
                        <Switch
                            value={user.isAnonymous}
                            onValueChanged={async (newValue) => {
                                await putUser(user.id, { isAnonymous: newValue })
                                await refresh()
                            }}
                        />
                    </div>
                    <div style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ fontSize: 14, fontWeight: 700, marginTop: 8 }}>
                                LANGUAGE
                            </div>
                            <div style={{ fontSize: 16, fontWeight: 400, marginTop: 8 }}>
                                Select preferred language
                            </div>
                        </div>

                        <DropdownButton
                            variant="secondary"
                            title={locales?.map((loc) => {
                                if (loc.key === locale && loc.displayName)
                                    return localize(loc.displayName)
                            })}
                        >
                            {setLocale &&
                                locales?.map((loc) => (
                                    <Dropdown.Item
                                        style={{
                                            backgroundColor:
                                                loc.key === locale ? "#E6EBED" : undefined,
                                            display: "flex",
                                        }}
                                        onClick={() => setLocale(loc.key)}
                                    >
                                        {loc.flagImage && (
                                            <Image
                                                src={loc.flagImage as unknown as string}
                                                style={{
                                                    width: 24,
                                                    height: 16,
                                                    marginRight: 6,
                                                }}
                                            ></Image>
                                        )}
                                        <div style={{ marginRight: 10 }}>
                                            {!loc.flagImage ? loc.flag : undefined}
                                        </div>
                                        <div style={{ marginTop: loc.flagImage ? -1 : 0 }}>
                                            {loc.displayName ? localize(loc.displayName) : null}
                                        </div>
                                    </Dropdown.Item>
                                ))}
                        </DropdownButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Switch(props: { value?: boolean; onValueChanged?: (newValue: boolean) => Promise<void> }) {
    const [state, setState] = useState(props.value ?? false)
    return (
        <div
            onClick={async () => {
                setState(!state)
                try {
                    await props.onValueChanged?.(!state)
                } catch (error) {
                    // Reset state on error
                    setState(state)
                    alert(error)
                }
            }}
            style={{
                paddingRight: state ? 0 : 6,
            }}
        >
            {state ? <SwitchOn /> : <SwitchOff />}
        </div>
    )
}

function SwitchOff() {
    return (
        <svg
            width="57"
            height="43"
            viewBox="0 0 57 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.857 3.09215C23.597 2.99215 25.335 3.00015 27.075 3.00015C27.087 3.00015 35.892 3.00015 35.892 3.00015C37.666 3.00015 39.404 2.99215 41.143 3.09215C42.724 3.18215 44.264 3.37415 45.797 3.80315C49.024 4.70515 51.842 6.58915 53.879 9.26015C55.904 11.9142 57 15.1632 57 18.4992C57 21.8392 55.904 25.0862 53.879 27.7402C51.842 30.4102 49.024 32.2952 45.797 33.1972C44.264 33.6262 42.724 33.8172 41.143 33.9082C39.404 34.0082 37.666 33.9992 35.926 33.9992C35.914 33.9992 27.107 34.0002 27.107 34.0002C25.335 33.9992 23.597 34.0082 21.857 33.9082C20.277 33.8172 18.737 33.6262 17.204 33.1972C13.977 32.2952 11.159 30.4102 9.122 27.7402C7.097 25.0862 6 21.8392 6 18.5002C6 15.1632 7.097 11.9142 9.122 9.26015C11.159 6.58915 13.977 4.70515 17.204 3.80315C18.737 3.37415 20.277 3.18215 21.857 3.09215Z"
                fill="#E6EBED"
            />
            <g filter="url(#filter0_dd_2401_13274)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.5 32C28.9558 32 35 25.9558 35 18.5C35 11.0442 28.9558 5 21.5 5C14.0442 5 8 11.0442 8 18.5C8 25.9558 14.0442 32 21.5 32Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_2401_13274"
                    x="0"
                    y="0"
                    width="43"
                    height="43"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2401_13274"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_2401_13274"
                        result="effect2_dropShadow_2401_13274"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_2401_13274"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

function SwitchOn() {
    return (
        <svg
            width="57"
            height="43"
            viewBox="0 0 57 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.857 3.09215C17.597 2.99215 19.335 3.00015 21.075 3.00015C21.087 3.00015 29.892 3.00015 29.892 3.00015C31.666 3.00015 33.404 2.99215 35.143 3.09215C36.724 3.18215 38.264 3.37415 39.797 3.80315C43.024 4.70515 45.842 6.58915 47.879 9.26015C49.904 11.9142 51 15.1632 51 18.4992C51 21.8392 49.904 25.0862 47.879 27.7402C45.842 30.4102 43.024 32.2952 39.797 33.1972C38.264 33.6262 36.724 33.8172 35.143 33.9082C33.404 34.0082 31.666 33.9992 29.926 33.9992C29.914 33.9992 21.107 34.0002 21.107 34.0002C19.335 33.9992 17.597 34.0082 15.857 33.9082C14.277 33.8172 12.737 33.6262 11.204 33.1972C7.977 32.2952 5.159 30.4102 3.122 27.7402C1.097 25.0862 0 21.8392 0 18.5002C0 15.1632 1.097 11.9142 3.122 9.26015C5.159 6.58915 7.977 4.70515 11.204 3.80315C12.737 3.37415 14.277 3.18215 15.857 3.09215Z"
                fill="#289259"
            />
            <g filter="url(#filter0_dd_2401_13270)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.5 32C42.9558 32 49 25.9558 49 18.5C49 11.0442 42.9558 5 35.5 5C28.0442 5 22 11.0442 22 18.5C22 25.9558 28.0442 32 35.5 32Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_2401_13270"
                    x="14"
                    y="0"
                    width="43"
                    height="43"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2401_13270"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_2401_13270"
                        result="effect2_dropShadow_2401_13270"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_2401_13270"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
